<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center mb-4">
              <div class="col-md-6">
                <div class="form-inline navbar-search">
                  <div class="input-group">
                    <input
                      v-on:keyup="search"
                      name="title"
                      class="form-control bg-light border-0 small"
                      placeholder="Search"
                      aria-label="Search"
                      aria-describedby="basic-addon2"
                      v-model="filter"
                    />
                    <div class="input-group-append">
                      <button class="btn btn-search" @click="searchFor">
                        <i class="fas fa-search fa-sm"> </i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="table-responsive mb-0">
              <b-table
                striped
                bordered
                :per-page="0"
                :current-page="currentPage"
                :items="tableData.data"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                responsive="sm"
                class="table-bordered table-hover"
                :key="key"
              >
                <template v-slot:cell(status)="row">
                  <b-badge v-if="row.item.status" variant="primary"
                    >Active</b-badge
                  >
                  <b-badge v-else variant="primary">Inactive</b-badge>
                </template>
                <template v-slot:cell(edit)="row">
                  <router-link
                    :to="{
                      name: 'edit-institute-master',
                      params: { id: row.item.id },
                    }"
                  >
                    <i class="fa fa-edit edit-i"></i>
                  </router-link>
                </template>
                <template v-slot:cell(logo_image)="row">
                  <div v-if="row.item.logo_image" class="handle-width">
                    <viewer :images="[row.item.logo_image]">
                      <img :src="row.item.logo_image" height="80" width="80" />
                    </viewer>
                  </div>
                </template>
              </b-table>
            </div>
            <template v-if="tableData.total_pages > 1">
              <div class="data_paginate">
                <div
                  class="dataTables_paginate paging_simple_numbers d-flex justify-content-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <b-pagination
                      v-model="currentPage"
                      first-number
                      last-number
                      :total-rows="tableData.total"
                      :total-pages="tableData.total_pages"
                      :per-page="tableData.per_page"
                    >
                    </b-pagination>
                  </ul>
                </div>
                <div class="form-inline navbar-search">
                  <span class="mr-2">Go to a Particular Paginated Page :</span>
                  <div class="input-group">
                    <b-form-input
                      id="case"
                      v-model="pageNo"
                      placeholder="Enter Page No"
                      class="in-width"
                    >
                    </b-form-input>
                    <div class="input-group-append">
                      <button class="btn btn-search" @click="filterPage">
                        <i class="fas fa-search fa-sm"> </i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import MixinRequest from "../../../mixins/request";
import InstituteMixin from "../../../mixins/ModuleJs/institute-master";
import "viewerjs/dist/viewer.css";
import VueViewer from "v-viewer";
import Vue from "vue";
Vue.use(VueViewer);

export default {
  components: { Layout, PageHeader },
  mixins: [MixinRequest, InstituteMixin],
  data() {
    return {
      title: "Institute Master",
      items: [
        {
          text: "Back",
          href: "/",
        },
        {
          text: "list",
        },
      ],
    };
  },
  methods: {
    show(img) {
      this.$viewerApi({
        images: [img],
      });
    },
  },
};
</script>
