import { instituteMaster } from "../../js/path";

export default {
  data() {
    return {
      pageNo: null,
      submitted: false,
      status: false,
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: true,
      tableData: [],
      products: [],
      currentPage: 1,
      activeTab: "all",
      params: "",
      key: 0,
      fields: [
        {
          key: "id",
          label: "ID",
          sortable: true,
        },
        {
          key: "institute_master_ref_no",
          label: "Reference No",
        },
        {
          key: "institute_name",
        },
        {
          key: "logo_image",
          label: "Institute Logo",
        },
        {
          key: "status",
        },
        {
          key: "Country",
        },
        {
          key: "edit",
        },
      ],
      form: {
        name: "",
        image: "",
      },
      logo_image_url: "",
    };
  },
  methods: {
    searchFor() {
      if (this.filter.length > 1) this.fetchData();
      else if (this.filter.length == 0) this.fetchData();
    },

    search(event) {
      if (this.filter.length > 1) {
        if (event.keyCode == 13) {
          this.fetchData();
        }
      } else if (this.filter.length == 0) this.fetchData();
    },

    filterPage() {
      if (this.pageNo.length > 0) {
        this.currentPage = this.pageNo;
      }
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    async fetchData() {
      this.$store.commit("loader/updateStatus", true);
      let url = instituteMaster.getInstitudes;
      if (this.filter) {
        url += "?search=" + this.filter + "&page=" + this.currentPage;
      } else {
        url += "?page=" + this.currentPage;
      }
      try {
        const data = await this.getRequest(url);
        if (data.status) {
          this.tableData = data.response;
        }
        this.$store.commit("loader/updateStatus", false);
      } catch (err) {
        this.$store.commit("toast/updateStatus"),
          {
            status: true,
            icon: "error",
            title: "somthing went wrong",
          };
        this.$store.commit("loader/updateStatus", false);
      }
      this.$store.commit("loader/updateStatus", false);
    },

    readFile(e, txt) {
      if (txt == "logo_image") {
        this.logo_image = e.target.files[0];
        this.form.image = e.target.files[0];
        if (this.logo_image.size > 2 * 1000 * 1000) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Uploaded File is more than 2MB",
          });
          this.$refs.logo_image.reset();
          this.logo_image = "";
          this.form.image = "";
          return false;
        } else {
          this.logo_image_url = URL.createObjectURL(this.logo_image);
        }
      }
    },

    async submitData() {
      try {
        this.submitted = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Please Fill The Required Fields",
          });
          return false;
        }
        let url = instituteMaster.getInstitudes;
        url += `/${this.$route.params.id}`;

        const appendData = new FormData();
        console.log(this.logo_image);
        appendData.append("institute_name", this.form.name);

        if (this.logo_image) {
          appendData.append(
            "logo_image",
            this.logo_image ? this.logo_image : null
          );
        }
        appendData.append("_method", "PUT");

        const data = await this.postRequest(url, appendData);
        if (data.status) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
          this.$router.push("/institute-master");
        }
      } catch (err) {
        console.log(err);
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
    },

    async getInstitute(id) {
      console.log("getting data");
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = instituteMaster.getInstitudes;
        url += `/${id}`;
        const data = await this.getRequest(url);
        if (data.response) {
          if (
            data.response.institute_name !== null &&
            data.response.institute_name !== ""
          ) {
            this.form.name = data.response.institute_name;
          }
          if (
            data.response.logo_image !== null &&
            data.response.logo_image !== ""
          ) {
            this.logo_image_url = data.response.logo_image;
          }
        }
      } catch (err) {
        console.log(err);
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
  },

  watch: {
    currentPage: {
      handler: function (v) {
        if (v != "" && v != null) {
          this.fetchData(this.activeTab);
          this.pageNo = this.currentPage;
        }
      },
    },
  },
  created() {
    if (this.$route.name == "edit-institute-master") {
      this.getInstitute(this.$route.params.id);
    } else {
      this.fetchData();
    }
  },
};
